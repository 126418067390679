<template>
  <div
    class="hazop px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full">
        <TitleComponent title="HAZOP Basis" size="large" />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/HAZOP_flow-min.png"
              width="611"
              height="786"
              alt=""
              title="HAZOP_flow"
              class="img-responsive wp-image-11857"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <Paragraph>
            <p>
              A HAZOP, or Hazard and Operability analysis, is a structured
              process hazards analysis technique in which a multi-discipline
              team performs a systematic study of a process using guide words to
              discover how deviations from the design intent can occur in
              equipment, actions, or materials, and whether the consequences of
              these deviations can result in a hazard.
            </p>
            <p>
              The results of the HAZOP analysis are the team’s recommendations,
              which include identification of hazards and the recommendations
              for changes in design, procedures, etc. to improve the safety of
              the system. Deviations during normal, startup, shutdown, and
              maintenance operations are discussed by the team and are included
              in the HAZOP. A block flow diagram of the HAZOP process is given
              to the left:
            </p>
            <p>
              The following terms are used in the HAZOP process and in the HAZOP
              table (see downloadable example below):
            </p>
            <ul>
              <li>
                Line No. – consists of an “Node/Operation/Item” number and a
                single letter identifying the specific “Failure Cause” (e.g.,
                1A, 1B, 2A…).
              </li>
              <li>
                Deviation – a departure from the design intent discovered by
                systematically applying guide words to process parameters.
              </li>
              <li>
                Failure Cause (guide words used) – simple words such as “high”
                pressure, “high” temperature, “leak” etc. that are used to
                modify the design intent and to guide and stimulate the process
                for identifying process hazards.
              </li>
              <li>Failure Cause – the reason why a deviation might occur.</li>
              <li>Potential Effects – the results of a deviation.</li>
              <li>
                Safeguards – engineered systems or administrative controls that
                prevent the causes or mitigate the consequences of deviations.
              </li>
              <li>
                Hazard Category – an assessment of the hazard risk of the
                operation. In most analyses, we have used the MIL-STD-882E,
                <router-link style="color:#aa222c;" to="/risk-assessment-matrix"
                  >“Hazard Risk Assessment Matrix.”</router-link
                >
              </li>
              <li>
                Recommendations – recommendations for design changes, procedural
                changes, or for further study.
              </li>
            </ul>
            <p>
              Guide words for flow in a chemical process include High Flow,
              No/Low Flow, Reverse Flow, Misdirected Flow, High Pressure, Low
              Pressure, High Temperature, Low Temperature, High Contaminants,
              Leak and Rupture.
            </p>
            <p>
              For processes utilizing explosives, the guide words include
              electrical initiation, ESD spark, Impact, Friction, Impingement,
              Incompatibilities, Explosive shock, Thermal ignition, Propagation,
              Personnel Injury, Environmental contamination, Equipment damage
              and Product damage.
            </p>
          </Paragraph>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Hazard and Operability analysis",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "A Hazard and Operability (HAZOP) analysis is a systematic study performed by a multi-disciplinary team to assess how deviations from design intent can occur as well as the consequences. HAZOP analysis uses a series of guide words to identify hazards and recommendations during all operation phases."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
